"use strict";

let toExport = {};

if(!process.env.GCLOUD_PROJECT_ON_BUILD && process.env.GCLOUD_PROJECT) {
  process.env.GCLOUD_PROJECT_ON_BUILD = process.env.GCLOUD_PROJECT;
}

let links = {
  site: {
    name: 'Site',
    link: 'https://v.voxtom.co/'
  },
  /* facebook: {
     name: 'Facebook',
     link: 'https://www.facebook.com/Sauver-112736837169018'
   },
   instagram: {
     name: 'Instagram',
     link: 'https://www.instagram.com/sauver_med'
   } */
};


const android = {
  linkStore: 'https://play.google.com/store/apps/developer?id=JustWorks',
  storeCode: '',
  id: 'br.com.justworks.jw-vue-boilerplate',
  uri: 'vnd.jw_vue_boilerplate://',
  name: 'Voxtom',
  packageName: 'br.com.justworks.jw-vue-boilerplate'
};

const ios = {
  linkStore: 'https://apps.apple.com/br/developer/alexandre-reis-corr%C3%AAa-cruz/id429125678',
  storeCode: 'id15168907wer',
  id: 'id15168907we',
  uri: 'vnd.jw_vue_boilerplate://',
  name: 'Voxtom',
  packageName: '',
};

toExport.listConfig = {
  'default': {
    name: 'Default ALGO DE ERRADO NÃO ESTA CERTO',
    firebase: null,
    fbId: '446124909121871',
    title: 'Voxtom'+(!(process.env.NODE_ENV === 'production' || process.env.VUE_APP_DEVELOPER_DATABASE_MODE === 'production')?' DEV ':''),
    description: 'Voxtom!',

    baseUrl: 'https://v.voxtom.co',
    links: links,
    android: android,
    ios: ios,
    themeColor: '#21ff00',
    msTileColor: '#000000',
    appleMobileWebAppCapable: 'no',
    appleMobileWebAppStatusBarStyle: 'default',
    emailKey: 'voxtom.com',
    region: 'southamerica-east1',
    isDevServer: true,
    functionsUrl: 'https://southamerica-east1-musicvoxtom.cloudfunctions.net',
  },
  'musicvoxtom': {
    name: 'voxtom PRODUCTION',
    maps: {
      key: 'AIzaSyCEaWrlGGdiwJrKTB80qelafIEBmji36Mg'
    },
    title: 'Voxtom',
    description: 'Voxtom!',

    baseUrl: 'https://v.voxtom.co',
    firebase: {
      apiKey: "AIzaSyCEaWrlGGdiwJrKTB80qelafIEBmji36Mg",
      authDomain: "musicvoxtom.firebaseapp.com",
      databaseURL: "https://musicvoxtom-default-rtdb.firebaseio.com",
      projectId: "musicvoxtom",
      storageBucket: "musicvoxtom.appspot.com",
      messagingSenderId: "383907643925",
      appId: "1:383907643925:web:a8e97aaa0b3115369e841c",
      measurementId: "G-0RS42Z4QYW"
    },
    functionsUrl: 'https://southamerica-east1-musicvoxtom.cloudfunctions.net',
    fbId: '446124909121871',
    links: links,
    android: android,
    ios: ios,
    themeColor: '#000000',
    msTileColor: '#000000',
    appleMobileWebAppCapable: 'yes',
    appleMobileWebAppStatusBarStyle: 'black',
    emailKey: 'voxtom.com',
    region: 'southamerica-east1',
    isDevServer: false,
  },
  'developer': {
    name: 'voxtomdevjw DEVELOPER',
    maps: {
      key: 'AIzaSyCEaWrlGGdiwJrKTB80qelafIEBmji36Mg'
    },
    title: 'Voxtom DEV ',
    description: 'Voxtom!',

    baseUrl: 'https://v.voxtom.co',
    firebase: {
      apiKey: "AIzaSyCa3dgdbZa1LIr8PfG1Z4PPZCSR9QcDj9I",
      authDomain: "musicvoxtomdev.firebaseapp.com",
      databaseURL: "https://musicvoxtomdev-default-rtdb.firebaseio.com",
      projectId: "musicvoxtomdev",
      storageBucket: "musicvoxtomdev.appspot.com",
      messagingSenderId: "760425407069",
      appId: "1:760425407069:web:9fb0474fce949adf27e3b4",
      measurementId: "G-CDPWDW14G3"
    },
    functionsUrl: 'https://southamerica-east1-volandevjw.cloudfunctions.net',
    fbId: '446124909121871',
    links: links,
    android: android,
    ios: ios,
    themeColor: '#21ff00',
    msTileColor: '#000000',
    appleMobileWebAppCapable: 'no',
    appleMobileWebAppStatusBarStyle: 'default',
    emailKey: 'voxtom.com',
    region: 'southamerica-east1',
    isDevServer: true,
  },
  'musicvoxtomdev': {
    name: 'voxtomdevjw DEVELOPER',
    maps: {
      key: 'AIzaSyCEaWrlGGdiwJrKTB80qelafIEBmji36Mg'
    },
    title: 'voxtomdevjw DEV ',
    description: 'Voxtom!',

    baseUrl: 'https://v.voxtom.co',
    firebase: {
      apiKey: "AIzaSyCa3dgdbZa1LIr8PfG1Z4PPZCSR9QcDj9I",
      authDomain: "musicvoxtomdev.firebaseapp.com",
      databaseURL: "https://musicvoxtomdev-default-rtdb.firebaseio.com",
      projectId: "musicvoxtomdev",
      storageBucket: "musicvoxtomdev.appspot.com",
      messagingSenderId: "760425407069",
      appId: "1:760425407069:web:9fb0474fce949adf27e3b4",
      measurementId: "G-CDPWDW14G3"
    },
    functionsUrl: 'https://southamerica-east1-musicvoxtomdev.cloudfunctions.net',
    fbId: '446124909121871',
    links: links,
    android: android,
    ios: ios,
    themeColor: '#21ff00',
    msTileColor: '#000000',
    appleMobileWebAppCapable: 'no',
    appleMobileWebAppStatusBarStyle: 'default',
    emailKey: 'voxtom.com',
    region: 'southamerica-east1',
    isDevServer: true,
  },
};
toExport.config = toExport.listConfig['default'];

if(process.env.GCLOUD_PROJECT_ON_BUILD==='musicvoxtomdev') {
  toExport.config = toExport.listConfig['musicvoxtomdev'];
  console.log('Server config start configName: ' + toExport.config.name+ ' on project: '+(process.env.GCLOUD_PROJECT_ON_BUILD||''));
}
else if (process.env.GCLOUD_PROJECT_ON_BUILD==='musicvoxtom') {
  toExport.config = toExport.listConfig['musicvoxtom'];
  console.log('Server config start configName: ' + toExport.config.name+ ' on project: '+(process.env.GCLOUD_PROJECT_ON_BUILD||''));
}
else {
  toExport.config = toExport.listConfig['developer'];
  console.log('Server config start configName: ' + toExport.config.name+ ' on project: '+(process.env.GCLOUD_PROJECT_ON_BUILD||''));
}

// console.log('Server config start configName: ' + toExport.config.name);

toExport.appConfig = toExport.config;

module.exports = toExport;
