import store from '@src/store';
import defaults from '@src/router/defaults';
const Workspace = require ('@common/Workspace');
import AnesthetistEdit from "@components/cruds/UserEdit";

// Workspace Workspace.name.ADMIN tem acesso ao workspace Workspace.name.COMPANY
// O primeiro workspace é utilizado para fazer um possível redirecionamento

const routes = [



  // VISITANT AND GENERIC
  // {
  //   path: '/',
  //   name: 'home',
  //   alias: ['/index.html'],
  //   component: () => lazyLoadView(import('@views/generic/nextHome')),
  // },
  {
    path: '/',
    name: 'home',
    alias: ['/index.html'],
    // redirect: { name: 'generic/home' }
    // component: () => lazyLoadView(import('@views/generic/Home')),

    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://voxtom.co");
    }

  },
  // {
  //   path: '/blog/:id?',
  //   name: 'blog',
  //   component: () => lazyLoadView(import('@views/generic/blog')),
  // },
  // {
  //   path: '/post/:id?',
  //   name: 'post',
  //   component: () => lazyLoadView(import('@views/generic/post')),
  // },
  // {
  //   path: '/faq/:id?',
  //   name: 'faq',
  //   component: () => lazyLoadView(import('@views/visitant/Faq')),
  // },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: () => lazyLoadView(import('@views/generic/ResetPassword')),
  },
  {
    path: '/login',
    name: 'login',
    redirect: { name: 'admin/login' }
  },


  // ADMIN
  {
    path: '/admin',
    redirect: { name: 'admin/login' }
  },
  {
    path: '/admin/login',
    name: 'admin/login',
    component: () => lazyLoadView(import('@views/admin/Login')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['auth/loggedIn']) {
          next({ name: defaults.routeLogged[store.getters['auth/loggedIn']] })
        } else {
          next();
        }
      },
    },
  },
  {
    path: '/admin/dashboard/:id?',
    name: 'admin/dashboard',
    component: () => lazyLoadView(import('@views/admin/Dashboard')),
    meta: {
      authRequired: true,
      workspaces: [Workspace.name.ADMIN],
    },
  },
  {
    path: '/admin/admin_users/:id?',
    name: 'admin/admin_users',
    component: () => lazyLoadView(import('@views/admin/AdminUsers')),
    meta: {
      authRequired: true,
      workspaces: [Workspace.name.ADMIN],
    },
  },
  {
    path: '/admin/Help/:id?',
    name: 'admin/Help',
    component: () => lazyLoadView(import('@views/admin/Faqs')),
    meta: {
      authRequired: true,
      workspaces: [Workspace.name.ADMIN],
    },
  },
  {
    path: '/admin/HelpCategories/:id?',
    name: 'admin/HelpCategories',
    component: () => lazyLoadView(import('@views/admin/FaqsCategories')),
    meta: {
      authRequired: true,
      workspaces: [Workspace.name.ADMIN],
    },
  },
  {
    path: '/admin/users/:id?',
    name: 'admin/users',
    component: () => lazyLoadView(import('@views/admin/Users')),
    meta: {
      authRequired: true,
      workspaces: [Workspace.name.ADMIN],
    },
  },

  {
    path: '/admin/publicity/:id?',
    name: 'admin/publicity',
    component: () => lazyLoadView(import('@views/admin/Publicity')),
    meta: {
      authRequired: true,
      workspaces: [Workspace.name.ADMIN],
    },
  },
  {
    path: '/admin/Genres/:id?',
    name: 'admin/Genres',
    component: () => lazyLoadView(import('@views/admin/Genres')),
    meta: {
      authRequired: true,
      workspaces: [Workspace.name.ADMIN],
    },
  },



  // CLIENT
  {
    path: '/client',
    redirect: { name: 'client/login' }
  },
  {
    path: '/client/login',
    name: 'client/login',
    component: () => lazyLoadView(import('@views/client/Login')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['auth/loggedIn']) {
          next({ name: defaults.routeLogged[store.getters['auth/loggedIn']] })
        } else {
          next()
        }
      },
    },
  },
  {
    path: '/client/dashboard/:id?',
    name: 'client/dashboard',
    component: () => lazyLoadView(import('@views/client/Dashboard')),
    meta: {
      authRequired: true,
      workspaces: [Workspace.name.CLIENT],
    },
  },
  {
    path: '/client/profileEdit/:id?',
    name: 'client/profileEdit',
    component: () => lazyLoadView(import('@views/client/Profile')),
    meta: {
      authRequired: true,
      workspaces: [Workspace.name.CLIENT],
    },
  },







  {
    path: '/logout',
    name: 'logout',
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        let routeTmp = defaults.routeLogOut[store.getters['auth/loggedIn']]
        store.dispatch('auth/logOut')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.meta.authRequired
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: routeTmp || 'login' } : { ...routeFrom })
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => lazyLoadView(import('@views/generic/_404')),
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    name: '*',
   // redirect: '404',
    component: () => lazyLoadView(import('@views/generic/_404')),
  },


  {
    path: '/tools/img',
    name: 'tools/img',
    component: () => lazyLoadView(import('@views/tools/Img'))
  },
];

// Rotas apenas para dev
if(process.env.NODE_ENV !== 'production') {
  const requireModule = require.context(
    '../views/demos/',
    false,
    /^((?!index|\.unit\.).)*\.vue$/
  )
  const demoRoutes = [];
  requireModule.keys().forEach((fileName) => {
    let file = fileName.substr(2).split('.')[0];
    if(file!=='index') {
      routes.push({
        path: '/demos/'+file+'/:id?',
        name: 'demos/'+file,
        component: () => lazyLoadView(import('@views/demos/'+file))
      });
      demoRoutes.push('demos/'+file);
    }

  });

  routes.push({
    path: '/demos',
    name: 'demos',
    component: () => lazyLoadView(import('@views/demos/index')),
    meta: {
      demoRoutes: demoRoutes
    },
  });

}






// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView, params = {}) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/generic/_loading'),
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/generic/_timeout'),
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}





export default routes;
